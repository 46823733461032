<template>
    <div class='sign-up-header'>
            <img src='../../public/Invrs-Logo-Green-Blue.png' width='250' style="cursor: pointer;" @click="goHome()">
            
    </div>

    <div class='sign-in-container'>
        <span class='sign-in-title'>Affiliate Sign In.</span>
                <div class='input-container'>
                    <span class='p-float-label' style="padding-right: 10px;">
                        <InputText id="username" type="text" v-model="userName"/>
                        <label for="username" class='input-label'>Email</label>
                    </span>
                    <span class='p-float-label'>
                        <InputText id="password" type="password" v-model="password"/>
                        <label for="password" class='input-label'>Password</label>
                    </span>
                </div>
                <div class='button-container'>
                    
            
                    <Button label='Sign In' class='sign-in-button' @click="signIn" :loading="loading"/>
                </div>
                
    </div>
    <Toast position="center" group="center" style="z-index:30000;" :autoZIndex="false"/>
</template>

<script>

import UserService from '../service/UserService';
import AppService from '../service/AppService';//eslint-disable-line



import Toast from 'primevue/toast';

const SUCCESS = 'success';
export default {
    name: 'AffiliateLogin',

    components: {
        Toast
    },
    data () {
        return {
            userName: null,
            password: null,
            loading: false,
        }
    },
    mounted() {
        AppService.cleanUpApp();// in case they logged in previously
        console.log("mounting affiliate login")
    },

    methods: {
       

       
        signIn() {
            this.loading = true;
            UserService.loginAffiliate(this.userName, this.password).then( (resp) => {
                if( resp.data.status == SUCCESS){
                    this.$store.commit("SET_AFFILIATE", resp.data.affiliate );
                    this.$store.commit("SET_TOKEN", resp.data.affiliateAccessToken)
                    this.$router.push({path : "/affiliate/home"});
                }else {
                    let msg = (resp.data.msg ?  resp.data.msg : "Sorry an expected error occurred during login.")
                    this.$toast.add({ severity: 'error', summary: msg, life: 2500, group: 'center' });
                    this.loading = false;
                }
            });
        },
        
    }
}
</script>

<style scoped>
* {
    font-family: "Trebuchet MS", "Verdana";
}

.sign-up-header {
    display: flex;
    align-items: center;
    padding: 10px 35px 30px;
}
.p-button-text {
    font-weight: bold;
    color: #32364e;
}
.p-button-text:enabled:hover {
    background: none;
    color: #33CC99;
}
.p-button-text:enabled:focus {
    box-shadow: none;
    border: none;
}
.forgot-password-button {
    margin-right: auto;
    font-size: 22px;
    padding: 9px 0px;
    border: none;
}
.sign-up-button {
    margin-left: auto;
    font-size: 25px;
    padding: 9px 15px;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 200px auto auto auto;
}

.sign-in-title {
    font-size: 60px;
    color: #32364e;
    padding-bottom: 0.7em;
}

.input-container {
    display: flex;
    padding-bottom: 35px;
}

.p-inputtext {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}
.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    width: 50%;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.button-container {
    display: flex;
}

.sign-in-button {
    margin-left: auto;
    width: 150px;
    border-radius: 8px;
    background-color: #33CC99;
    font-size: 25px;
    padding: 9px 15px;
    color: white;
    border: 1px solid #33CC99;
    justify-content: center;
}
.sign-in:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

</style>
